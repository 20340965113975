import React, { useState } from "react"
import './footer.scss'
import trackEvent from "../analyticsHelpers"
import './newLocalization.scss'
import MapaParaiso from '../images/mapa-paraiso-porto.png'

const PortoLocalization = () => {
 const placeMapsUrl = 'https://www.google.com/maps/place/Av.+Ang%C3%A9lica,+2491+-+Consola%C3%A7%C3%A3o,+S%C3%A3o+Paulo+-+SP,+01227-200,+Brasil/@-23.5538251,-46.6640429,17z/data=!3m1!4b1!4m5!3m4!1s0x94ce582decf628d5:0xd55ff9e2c377f850!8m2!3d-23.5538251!4d-46.6618542'
  return (
    <>
      <div className="flex items-center px-0 lg:px-20 5 flex-col lg:flex-row">
        <a href={placeMapsUrl}  target="_blank">
          <img src={MapaParaiso} alt="" className="img-map" />
        </a>
        <div className="flex flex-col">
          <p className="text-white text-dsTextMedium lg:text-dsTextLarge text-left  md:w-full lg:ml-0 mx-auto text-center lg:text-left address-porto">Av. Angélica, 2491 - Consolação, São Paulo - SP, 01227-200</p>
          <a className="text-white text-textSmaller text-left mt-6 text-center lg:text-left" target="_blank" href={placeMapsUrl}>Ver no mapa</a>
        </div>
      </div>
    </>
  )
}

export default PortoLocalization
