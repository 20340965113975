import React, { useEffect } from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo'

import Layout from "../components/layout"
import FirstCel from '../images/print1.png'
import SecondCel from '../images/print2.png'
import ThirdCel from '../images/print3.png'
import './index-new.scss'
import trackEvent from "../analyticsHelpers"
import RegularButton from '../components/atoms/buttons/RegularButton'
import ImgTopo from '../images/topo-porto.png'
import CNN from '../images/logo-cnn.png'
import Forbes from '../images/logo-forbes.png'
import FastCompany from '../images/logo-fast-company.png'
import Estadao from '../images/logo-estadao.png'
import { Divider } from "../components/atoms/lines/Divider"
import PortoLocalization from "../components/portoLocalization"
import ReviewSlider from '../components/reviewSlider'
import FontIcon from "../components/atoms/fontIcon/FontIcon"
import Ellipse1 from '../images/ellipse-porto.png'
import Ellipse2 from '../images/ellipse2-porto.png'
import Ellipse3 from '../images/ellipse-porto-mobile.png'
import Ellipse4 from '../images/ellipse2-porto-mobile.png'
import CheckRosa from '../images/check-rosa.png'
import FotoPorto from '../images/foto1-porto.png'
import FotoPorto2 from '../images/foto2-porto.png'
import FotoPorto3 from '../images/foto1-porto-mob.png'
import FotoPorto4 from '../images/foto2-porto-mob.png'
import ImgTopoMob from '../images/topo-porto-mobile.png'
import Android from '../images/loja_android.png'
import { goToApp } from "../utils"

declare global { interface Window { analytics: any; } }



function PortoSeguro() {
  useEffect(() => {
    trackEvent("sitenovo-landing-page-porto-seguro-visualizou-site")
  }, [])
 
  return (
    <Layout>
      <GatsbySeo
        title='Obstetras e ginecologistas que atendem Porto Seguro Saúde em São Paulo'
        description='Tenha o melhor cuidado na sua gravidez com ginecologistas e obstetras que atendem Porto Seguro Saúde em uma rede de cuidado que nasceu para colocar a mulher no centro do cuidado. '
        language='pt-br'
        canonical='https://blog.theia.com.br/obstetras-porto-seguro-saude'
        openGraph={{
          type: 'website',
          url: 'https://blog.theia.com.br/obstetras-porto-seguro-saude',
          title: 'Obstetras e ginecologistas que atendem Porto Seguro Saúde em São Paulo',
          description: 'Tenha o melhor cuidado na sua gravidez com ginecologistas e obstetras que atendem Porto Seguro Saúde em uma rede de cuidado que nasceu para colocar a mulher no centro do cuidado. ',
          images: [
            {
              url: 'https://theia.com.br/theia.png',
              width: 250,
              height: 367,
              alt: 'Obstetras e ginecologistas que atendem Porto Seguro Saúde em São Paulo',
            },
          ],
        }}
        metaTags={[{
          name: 'dc:title',
          content: 'Obstetras e ginecologistas que atendem Porto Seguro Saúde em São Paulo'
        }, {
          name: 'dc:description',
          content: 'Tenha o melhor cuidado na sua gravidez com ginecologistas e obstetras que atendem Porto Seguro Saúde em uma rede de cuidado que nasceu para colocar a mulher no centro do cuidado. '
        }, {
          name: 'dc:language',
          content: 'BR',
        }, {
          name: 'reply-to',
          content: 'suporte@theia.com.br',
        }, {
          name: 'author',
          content: 'Theia',
        }, {
          name: 'description',
          content: 'Tenha o melhor cuidado na sua gravidez com ginecologistas e obstetras que atendem Porto Seguro Saúde em uma rede de cuidado que nasceu para colocar a mulher no centro do cuidado. ',
        }, {
          name: 'keywords',
          content: 'gravidez, sintomas de gravidez, testes de gravidez, ginecologistas, obstetras, pré-natal, pre natal, semanas de gestacao, ensaio gestante, exame de gravidez, mulher gravida, semanas gestacionais, gravidez ectopica, consulta pre natal, consulta pré natal, parto, períneo, parto normal, parto humanizado, puerpério, cesaria, cesariana, parto cesaria, parto cesária, pre-natal com plano, pré-natal porto seguro, obstetra porto seguro',
        },
        , {
          property: 'twitter:card',
          content: 'summary_large_image',
        }
      ]}
      />
      <section className="section-1 grid-wrapper-section items-center lg:items-start">
        <div className="item1">
          <img src={Ellipse4} alt="" className="ellipse4-porto block lg:hidden" />
          <h1 className="text-dsTitle2 lg:text-dsTitle1 font-medium text-primary main-title-porto">
            Theia está na rede da Porto Seguro Saúde
          </h1>
          <div className="block lg:hidden mt-4">
            <div className="image-cards-container-porto relative">
              <img src={Ellipse3} alt="" className="ellipse3-porto" />
              <img src={ImgTopoMob} alt="" className="mx-auto img-topo-mob" />
            </div>
          </div>
          <div className="flex lg:mt-12 mt-6 mb-12 flex-col lg:flex-row">
           <p className="text-textSmall text-center lg:text-left lg:text-landingParagraph text-textSecondary">
           Quem tem Porto Seguro Saúde
           pode contar com o atendimento atualizado, humano e prático da
           Theia na sua gravidez.
           </p>
          </div>
         
          <RegularButton
            onClick={() => goToApp('https://app.theia.com.br/boas-vindas', 'sitenovo-landing-page-porto-seguro-clicou-agende-sua-consulta')}
            label="Agende sua consulta"
            extraClass="btn-section-1"
          />
          <img src={Ellipse1} alt="" className="ellipse2-porto hidden lg:block" />
          <img src={Ellipse2} alt="" className="ellipse3-porto hidden lg:block" />
        </div>
        <div className="item2 self-end hidden lg:block">
          <div className="relative image-cards-container porto-seguro">
            <img src={ImgTopo} alt="" className="float-right relative topo-porto" />
            <img src={Ellipse1} alt="" className="ellipse1-porto" />
          </div>
        </div>
      </section>

      <section className="section-2 items-center lg:items-start">
        <h2 className="text-white text-titleMedium lg:text-dsTitle2 text-center">
          Quem tem Theia ama
        </h2>
      <div className="contents">
          <ReviewSlider />
        </div>
      </section>

      <section className="section-3 grid-wrapper-section items-center lg:items-start">
        <div className="item1 text-center">
          <h2 className="text-dsTextMedium lg:text-dsTitle2 text-primary mx-auto title-porto">
          Aqui na Theia temos a missão de levar para cada vez
          mais grávidas um atendimento de qualidade, atualizado
          e respeitoso, que você pode confiar que estará com
          você desde antes de engravidar, passando pela
          gravidez, parto e pós-parto.
          </h2>
          <div className="flex justify-center lg:mx-auto mt-12 lg:mt-6 flex-col">
           <h2 className="text-dsTitle2 text-primary">
            Entenda quais planos Porto Seguro Saude atendemos:
           </h2>
           <p className="text-dsTextSmal lg:text-dsTextMedium mt-6 text-textSecondary">
            A Theia esta disponível online para todo o Brasil e presencialmente na cidade de São Paulo para quem tem
            Porto Seguro Saúde Ouro ou Diamante, e quem tem os Planos da Fundação Itaú Saúde Executivo ou Premium.
           </p>
           <RegularButton
            onClick={() => goToApp('https://theia.com.br/quem-somos/', 'sitenovo-landing-page-porto-seguro-clicou-conheca-mais-sobre-Theia')}
            label="Conheça mais sobre a Theia e nossa filosofia de cuidado"
            extraClass="btn-section-3-porto my-12 mx-auto"
          />
          </div>
        </div>
      </section>

      <section className="section-6 items-center lg:items-start relative">
        <img alt="" src={FotoPorto} className="foto-porto hidden lg:block" />
        <h2 className="text-dsTitle2 text-primary text-center w-full item-1 mx-auto">
          Faz parte do seu Plano de Saúde Porto Seguro
        </h2>
        <ul className="item-2 text-textSecondary text-dsTextSmal lg:text-dsTextMedium">
          <li className="flex items-baseline lg:items-center">
            <img src={CheckRosa} alt="" className="mr-1" />
            <span>toda assistência de consultas pré-natal com obstetra Theia</span>
          </li>
          <li className="flex items-baseline lg:items-center">
            <img src={CheckRosa} alt="" className="mr-1" />
            <span>agendamento super prático pelo app ou site</span>
          </li>
          <li className="flex items-baseline lg:items-center">
            <img src={CheckRosa} alt="" className="mr-1" />
            <span>acompanhamento com uma especialista pessoal Theia</span>
          </li>
          <li className="flex items-baseline lg:items-center">
            <img src={CheckRosa} alt="" className="mr-1" />
            <span>acesso a conteúdos e indicações de consulta</span>
          </li>
          <li className="flex items-baseline lg:items-center">
            <img src={CheckRosa} alt="" className="mr-1" />
            <span>assistência do time Theia no seu parto</span>
          </li>
        </ul>
        <RegularButton
          onClick={() => goToApp('https://app.theia.com.br/boas-vindas', 'sitenovo-landing-page-porto-seguro-clicou-agende-primeira-consulta')}
          label="Agende agora sua primeira consulta
          com obstetra Theia pelo seu plano"
          extraClass="btn-section-6-porto my-12 mx-auto"
        />
        <img alt="" src={FotoPorto3} className="block lg:hidden mb-6" />
      </section>

      <section className="section-7 items-center lg:items-start relative">
        <h2 className="text-titleMedium lg:text-dsTitle2 text-primary text-center w-full item-1 mx-auto">
          Quer seguir recebendo acompanhamento da nossa equipe após o bebê nascer?
        </h2>
        <p className="text-dsTextSmal lg:text-dsTextMedium mt-6 text-textSecondary item-2 mx-auto text-center">
          Não tem problema, nosso time de pediatras também faz parte do seu plano de saúde <span className="text-darkPinkColor">:)</span>
        </p>
        <img alt="" src={FotoPorto4} className="block lg:hidden mb-6 mt-4" />
      </section>


      <section className="section-8 items-center lg:items-start relative">
        <img alt="" src={FotoPorto2} className="foto-porto-2 hidden lg:block" />
        <h2 className="text-dsTitle2 lg:text-dsTitle2 text-primary text-center w-full item-1 mx-auto">
          E não se preocupe: a Theia está em constante evolução.
        </h2>
        <p className="text-dsTextSmal lg:text-dsTextLarge mt-6 text-textSecondary item-2 mx-auto text-center">
          Caso você não tenha encontrado seu plano, estamos ampliando nossa parceria com outras operadoras. Tudo para que você tenha a melhor experiência na sua gravidez.
        </p>
      </section>
      <section className="relative w-full flex flex-col section-4 grid-wrapper-section-4 items-center lg:items-start">
        <div className="w-full text-center mt-8 lg:mt-20">
          <h2 className="text-white text-titleMedium lg:text-dsTitle2">Nossa localização</h2>
        </div>
        <div className="section-4-second-container text-center w-full flex flex-col items-center mb-16 lg:mb-0">
          <PortoLocalization />
        </div>
      </section>

      <section className="section-5 grid-wrapper-section text-center items-center lg:items-start">
            
        <h2 className="item1 text-titleMedium lg:text-dsTitle2 text-primary text-center">
          Theia na mídia
        </h2>
        <div className="item2 flex flex-col text-left mt-8 lg:mt-0">
          <span className="media-icon"><img src={Estadao} alt="" className="mx-auto" /></span>
            <p className="text-dsTextSmal text-textPrimary">
            "Já a Theia mira um mercado grande e cheio de lacunas: saúde da mulher. A empresa, fundada por Paula Crespi e Flávia Deustch, tem uma rede de 38 especialistas para atendimentos em pré-natal, parto e pós-parto - além de ginecologistas, há profissionais como psicólogos, nutricionistas e pediatras."
            </p>
            <Divider
              maxWidth="100%"
              border="1px solid #EEEEEE"
              className="mt-6 mb-2"
            />
            <p className="text-textSecondary text-dsTextSmal italic">Julho/2021 • Estadão</p>
            <a href="https://www.estadao.com.br/infograficos/link,healthtechs-ganham-espaco-e-mostram-que-estao-saudaveis,1182071" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
        </div>
        <div className="item3 flex flex-col text-left mt-4 lg:mt-0">
          <span className="media-icon media-icon-second"><img src={CNN} alt="" className="mx-auto" /></span>
          <p className="text-dsTextSmal text-textPrimary">
          "As fundadoras Flávia Deutsch e Paula Crespi conseguiram captar recursos em 2019 para o desenvolvimento da marca, levantando o maior investimento daquele ano conquistado por mulheres na América Latina"
          </p>
          <Divider
            maxWidth="100%"
            border="1px solid #EEEEEE"
            className="mt-6 mb-2"
          />
          <p className="text-textSecondary text-dsTextSmal italic">Abril/2021 • CNN</p>
          <a href="https://www.cnnbrasil.com.br/business/2021/04/22/menos-de-5-das-startups-sao-fundadas-por-mulheres-como-mudar-esse-cenario" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
        </div>
        <div className="item4 flex flex-col text-left mt-4 lg:mt-0">
          <span className="media-icon"><img src={FastCompany} alt="" className="mx-auto" /></span>
            <p className="text-dsTextSmal text-textPrimary">
            "Ainda raras, mulheres founders de startups inspiram setor"
            </p>
            <Divider
              maxWidth="100%"
              border="1px solid #EEEEEE"
              className="mt-6 mb-2"
            />
            <p className="text-textSecondary text-dsTextSmal italic">Fevereiro/2021 • Fast Company</p>
          <a href="https://fastcompanybrasil.com/tech/ainda-raras-mulheres-a-frente-de-startups-inspiram-setor/" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
        </div>
    
        <div className="item5 flex flex-col text-left mt-4 lg:mt-0">
          <span className="media-icon"><img src={Forbes} alt="" className="mx-auto" /></span>
            <p className="text-dsTextSmal text-textPrimary">
            ”A Theia se destacou ao se tornar a companhia fundada apenas por mulheres a conseguir levantar o maior valor em investimentos de 2019.”
            </p>
            <Divider
              maxWidth="100%"
              border="1px solid #EEEEEE"
              className="mt-6 mb-2"
            />
            <p className="text-textSecondary text-dsTextSmal italic">Setembro/2020 • Forbes</p>
          <a href="https://forbes.com.br/forbes-collab/2020/09/camila-farani-liderancas-femininas-revolucionam-as-startups/" target="_blank" className="text-textSmaller text-primary mt-3">Ler matéria</a>
        </div>
        <div className="item6 mt-8 lg:mt-0">
          <RegularButton
            onClick={() => goToApp('/midia', 'sitenovo-landing-page-porto-seguro-clicou-ver-mais-midia')}
            label="Ver mais"
            variant="secondary"
          />
        </div>
      </section>
    </Layout>
  )
}
    
export default PortoSeguro
    