import React from "react"
import Slider from "react-slick"
import Depo1 from "../images/img-juliana.png"
import Depo2 from "../images/img-veronica.png"
import Depo3 from "../images/img-jaqueline.png"
import Depo4 from "../images/img-amanda.png"
import Depo5 from "../images/img-isa.png"

import './reviewSlider.scss'

const ReviewSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    className: "slider center",
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          centerPadding: '40px'
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
          centerPadding: '40px'
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          centerPadding: '40px'
        }
      }
    ]
  }

  return (
      <div className="review-slider">
        <Slider {...settings} className="overflow-hidden">
          <div className="slide-review-item">
            <div className="flex flex-col items-center text-center">
              <img src={Depo1} alt="" />
              <p className="text-textSmall text-textPrimary mt-6">
                "Na Theia, encontrei atendimento multidisciplinar e olhar atencioso de todas as profissionais que me acompanharam. Me senti segura e acolhida durante todo o processo. É o pré-natal que todas nós merecemos nesse período tão único."
              </p>
              <span className="text-textSecondary text-textSmall mt-3">Juliana Alves Antunes</span>
            </div>
          </div>
          <div className="slide-review-item">
            <div className="flex flex-col items-center text-center">
              <img src={Depo2} alt="" />
              <p className="text-textSmalltext-textPrimary mt-6">
                "Até eu entender o funcionamento da Theia tinha sensação de que era uma prestação de serviços médicos. Não tinha entendido que era algo mais profundo que isso. Com o tempo e a forma que fomos acolhidas entendi a diferença entre você passar numa consulta e passar numa consulta com alguém da Theia. Nas últimas consultas reparei no e-mail, que assina Família Theia. E é exatamente assim que você se sente. Se sente parte, parte da família."
              </p>
              <span className="text-textSecondary text-textSmall mt-3">Veronica Oliveira</span>
            </div>
          </div>
          <div className="slide-review-item">
            <div className="flex flex-col items-center text-center">
              <img src={Depo3} alt="" />
              <p className="text-textSmall text-textPrimary mt-6">
                "Todos os profissionais que tive acesso através de vocês foram maravilhosos. Sempre falo pro meu marido que foi o dinheiro mais bem gasto do meu pré-natal!"
              </p>
              <span className="text-textSecondary text-textSmall mt-3">Jaqueline Zago</span>
            </div>
          </div>
          <div className="slide-review-item">
            <div className="flex flex-col items-center text-center">
              <img src={Depo4} alt="" />
              <p className="text-textSmall text-textPrimary mt-6">
                "Encontrar a Theia foi um achado nessa gestação! Após duas gestações finalizadas com cesáreas desnecessárias, queria ressignificar dentro de mim o que seria uma gestação e parto humanizados, ainda que a saída tivesse que ser a cesárea. Desde o primeiro contato fui muito acolhida e tive todo o suporte para o desafio dessa terceira gravidez. Sou acompanhada por uma equipe competente, incrível, que me ouve, auxilia nas angústias, me atende de maneira integral e que acima de tudo é humana!"
              </p>
              <span className="text-textSecondary text-textSmall mt-3">Amanda Tedeschi Greco Ohphata</span>
            </div>
          </div>
          <div className="slide-review-item">
            <div className="flex flex-col items-center text-center">
              <img src={Depo5} alt="" />
              <p className="text-textSmall text-textPrimary mt-6">
                "Encontrei a Theia em uma rede social e logo no primeiro contato me senti acolhida, o que se repete em todos os atendimentos. Somos uma grande família e, depois do meu filho, a melhor coisa que me aconteceu foi encontrar a Theia!"
              </p>
              <span className="text-textSecondary text-textSmall mt-3">Isabella Genaro</span>
            </div>
          </div>
        </Slider>
      </div>
  )
}


export default ReviewSlider